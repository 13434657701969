import { render, staticRenderFns } from "./blank-pages.vue?vue&type=template&id=3bccce65&scoped=true"
import script from "./blank-pages.vue?vue&type=script&lang=js"
export * from "./blank-pages.vue?vue&type=script&lang=js"
import style0 from "./blank-pages.vue?vue&type=style&index=0&id=3bccce65&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bccce65",
  null
  
)

export default component.exports