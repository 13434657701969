<template>
  <div v-if="isShow" class="blank-page">
    <div class="block">
      <div class="blank-content">
        <img :src="blankImg" alt="" class="blank-img" />
        <span class="title-lable">{{ prompt }}</span>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "blank-pages",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    blankImg: {
      type: String,
    },
    prompt: {
      type: String,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.blank-page {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgb(255, 255, 255);
  .blank-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .blank-img {
      display: block;
      width: 200px;
      height: auto;
    }
    .title-lable {
      margin-top: 3px;
      color: #aaaaaa;
      font-size: 15px;
    }
  }
}
</style>