<template>
  <div class="chitchat-list-page">
    <NavBar :iconColor="'#9B9B9B'" class="nav-bar">
      <div slot="title">我的消息</div>
    </NavBar>
    <div class="info-content">
      <div
        class="info-list"
        v-for="item in list"
        :key="item.id"
        @click="consultInfo(item.id)"
      >
        <div class="head-img-box">
          <div class="news-num" v-if="item.wait_read > 0">
            {{ item.wait_read }}
          </div>
          <img :src="item.logo" class="head-img" />
        </div>
        <div class="content-text">
          <div class="text-top">
            <span>{{ item.name }}</span>
            <span>{{ item.change_time }}</span>
          </div>
          <span class="text-bottom">{{ item.text }}</span>
        </div>
      </div>
    </div>
    <BlankPages
      :is-show="showBlank"
      :blankImg="blankImg"
      :prompt="prompt"
    ></BlankPages>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import BlankPages from "@/components/blank-pages/blank-pages";
import socket from "@/common/js/socket";
import { Toast } from "vant";
export default {
  name: "chitchat",
  components: {
    NavBar,
    BlankPages,
  },
  data() {
    return {
      list: [],
      page: 1,
      isEnd: 0,
      showBlank: false,
      blankImg: require("@/assets/blank/collect-blank-images@2x.png"),
      prompt: "暂无消息",
    };
  },
  mounted() {
    //事件注册
    let _that = this;
    //绑定获取消息通知
    socket.on("getImGroup", (ret) => {
      if (ret.status * 1 === 1) {
        _that.setMsgGroup(ret.data);
      } else {
        Toast.fail(ret.msg);
      }
    });
    this.getMsgGroup();
  },
  methods: {
    consultInfo(id) {
      this.$router.push({ name: "information", query: { groupId: id } });
    },
    getMsgGroup() {
      socket.emit("getImGroup", {
        page: this.page,
        group_type: 1,
      });
    },
    setMsgGroup(data) {
      if (this.isEnd === 1) {
        return false;
      }
      ++this.page;
      if (data.length > 0) {
        this.list = [...this.list, ...data];
      } else {
        this.isEnd = 1;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.chitchat-list-page {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.nav-bar {
  background-color: #fff;
  border-bottom: 1px solid rgb(241, 241, 241);
}
.info-content {
  flex: 1;
  margin-top: 64px;
  background-color: #fff;
  height: 100%;
  .info-list {
    display: flex;
    align-items: center;
    height: 82px;
    padding: 0 12px;
    &:active {
      background-color: rgb(243, 243, 243);
    }
    .head-img-box {
      position: relative;
      width: 55px;
      height: 55px;
      .news-num {
        position: absolute;
        top: -5px;
        right: 0;
        min-width: 20px;
        height: 20px;
        background: #ed301d;
        box-shadow: 0px 2px 3px rgba(237, 48, 30, 0.38);
        border-radius: 50%;
        opacity: 1;
        text-align: center;
        color: #fff;
        line-height: 20px;
      }
    }
    .head-img {
      flex-shrink: 0;
      display: block;
      width: 55px;
      height: 55px;
      border-radius: 50%;
    }
    .content-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin-left: 12px;
      border-bottom: 1px solid #f5f5f5;
      .text-top {
        display: flex;
        justify-content: space-between;
        span {
          &:nth-child(1) {
            font-size: 18px;
          }
        }
        span {
          &:nth-child(2) {
            font-size: 15px;
            color: #aaaaaa;
          }
        }
      }
      .text-bottom {
        width: 177px;
        margin-top: 8px;
        color: #6f6f6f;
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
